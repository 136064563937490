<template>
  <section>
    <TopBanner
      title="Settlement Agent Portal"
      :src="require('@/assets/images/banners/banner_aboutus.jpg')"
    />
    <v-container class="content-container">
      <div class="mb-4 text-right">
        <v-btn color="pink" outlined @click="showPdfViewerByPath">
          Instruction
          <v-icon right> mdi-file-question </v-icon>
        </v-btn>
      </div>
      <v-alert
        v-if="downloadDocsModel.errorMessage"
        outlined
        border="left"
        type="error"
      >
        {{ downloadDocsModel.errorMessage }}
      </v-alert>
      <v-card color="lightgrey">
        <v-card-text class="d-flex align-center justify-space-between">
          <div>
            File Access Confirmation (Input all required information in 3 fields
            below)
          </div>
          <div>
            <v-btn color="error" rounded @click="resetModel"> Reset </v-btn>
          </div>
        </v-card-text>
      </v-card>
      <v-card :disabled="step !== 0">
        <v-card-text>
          <v-form ref="verifyLoanInfoForm" @submit.prevent="verifyLoanInfo">
            <v-card class="pa-4" flat>
              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field
                    v-model="uploadDocsModel.uploadDocsVM.loanNumber"
                    label="Loan Number"
                    :counter="9"
                    :rules="[
                      (v) => !!v || 'Loan Number is required',
                      (v) =>
                        (v && v.length == 9) ||
                        'Loan Number must consist of 9 digits',
                    ]"
                    v-mask="'NNXXXXXXX'"
                    dense
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="uploadDocsModel.uploadDocsVM.ssN4"
                    label="Last 4-digit SSN"
                    :counter="4"
                    :rules="[
                      (v) => !!v || 'This field is required',
                      (v) =>
                        (v && v.length == 4) || 'This must consist of 4 digits',
                    ]"
                    v-mask="'XXXX'"
                    dense
                    required
                    :type="
                      uploadDocsModel.uploadDocsVM.ssN4Visiable
                        ? 'text'
                        : 'password'
                    "
                    :append-icon="
                      uploadDocsModel.uploadDocsVM.ssN4Visiable
                        ? 'mdi-eye'
                        : 'mdi-eye-off'
                    "
                    @click:append="
                      uploadDocsModel.uploadDocsVM.ssN4Visiable =
                        !uploadDocsModel.uploadDocsVM.ssN4Visiable
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="3">
                  <v-text-field
                    v-model="uploadDocsModel.uploadDocsVM.zip5"
                    label="Property zip"
                    :counter="5"
                    :rules="[
                      (v) => !!v || 'This field is required',
                      (v) =>
                        (v && v.length == 5) || 'This must consist of 5 digits',
                    ]"
                    v-mask="'XXXXX'"
                    dense
                    required
                    :type="
                      uploadDocsModel.uploadDocsVM.zip5Visiable
                        ? 'text'
                        : 'password'
                    "
                    :append-icon="
                      uploadDocsModel.uploadDocsVM.zip5Visiable
                        ? 'mdi-eye'
                        : 'mdi-eye-off'
                    "
                    @click:append="
                      uploadDocsModel.uploadDocsVM.zip5Visiable =
                        !uploadDocsModel.uploadDocsVM.zip5Visiable
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn
                    ref="submitBtn"
                    type="submit"
                    color="secondary"
                    :loading="this.stepLoading"
                  >
                    Verify
                  </v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-form>
        </v-card-text>
      </v-card>

      <br />

      <v-row>
        <v-col cols="12">
          <section v-if="downloadDocsModel.status === 200">
            <v-card color="secondary lighten-2">
              <v-card-text class="d-flex align-center justify-space-between">
                <div>
                  <span class="font-weight-bold"
                    >Settlement Agent Documents</span
                  >
                </div>
              </v-card-text>
            </v-card>
            <v-card>
              <v-card-text>
                <div
                  v-if="
                    downloadDocsModel.embeddedDocs.some(
                      (x) =>
                        x.documentCategoryCode === 'DOCS' && !x.documentTypeCode
                    )
                  "
                >
                  <div
                    v-for="doc in downloadDocsModel.embeddedDocs.filter(
                      (x) =>
                        x.documentCategoryCode === 'DOCS' && !x.documentTypeCode
                    )"
                    :key="`closing-doc-id-${doc.embeddedDocId}`"
                    class="my-1"
                  >
                    <v-icon class="mr-1" color="error" size="sm">
                      mdi-file-pdf-outline
                    </v-icon>
                    <!-- mdi-cloud-download-outline -->
                    <a
                      class="black--text"
                      href="javascript:void(0)"
                      @click="showPDFViewer(doc)"
                    >
                      {{ `${doc.description}.${doc.fileExtension}` }}
                    </a>
                  </div>
                </div>
                <div v-else class="warning--text">
                  <v-icon color="warning"> mdi-close-circle </v-icon>
                  Document does not exist
                </div>
              </v-card-text>
            </v-card>
          </section>
        </v-col>
        <v-col cols="12" md="6">
          <section v-if="uploadDocsModel.status === 200">
            <v-card color="error lighten-2">
              <v-card-text class="d-flex align-center justify-space-between">
                <div>
                  <span class="font-weight-bold"
                    >Uploaded Closing Packages</span
                  >
                </div>
              </v-card-text>
            </v-card>

            <v-card>
              <v-card-text v-if="uploadDocsModel.uploadDocsVM.uploadDate">
                <div
                  v-if="
                    downloadDocsModel.embeddedDocs.some(
                      (x) =>
                        x.documentCategoryCode === 'DOCS' &&
                        x.documentTypeCode === 'UploadClosingPkg'
                    )
                  "
                  class="text-body-1"
                >
                  <v-icon color="success"> mdi-check-circle </v-icon>
                  The document was first uploaded on
                  <span class="font-weight-bold">{{
                    uploadDocsModel.uploadDocsVM.uploadDate
                      | moment("MM/DD/YYYY")
                  }}</span
                  >.
                </div>

                <div
                  v-if="
                    downloadDocsModel.embeddedDocs.some(
                      (x) =>
                        x.documentCategoryCode === 'DOCS' &&
                        x.documentTypeCode === 'UploadClosingPkg'
                    )
                  "
                >
                  <div
                    v-for="doc in downloadDocsModel.embeddedDocs.filter(
                      (x) =>
                        x.documentCategoryCode === 'DOCS' &&
                        x.documentTypeCode === 'UploadClosingPkg'
                    )"
                    :key="`closing-doc-id-${doc.embeddedDocId}`"
                    class="d-flex my-1"
                  >
                    <v-icon class="mr-1" color="error" size="sm">
                      mdi-file-pdf-outline
                    </v-icon>
                    <a
                      class="black--text"
                      href="javascript:void(0)"
                      @click="showPDFViewer(doc)"
                    >
                      {{ `${doc.description}.${doc.fileExtension}` }}
                    </a>
                    <span class="ml-auto font-weight-bold">{{
                      doc.dateCreated | moment("MM/DD/YYYY h:mm a")
                    }}</span>
                  </div>
                </div>
                <div v-else class="warning--text">
                  <v-icon color="warning"> mdi-close-circle </v-icon>
                  Document does not exist
                </div>
              </v-card-text>
            </v-card>
          </section>
        </v-col>
        <v-col cols="12" md="6">
          <section v-if="uploadDocsModel.status === 200">
            <v-card color="error lighten-2">
              <v-card-text class="d-flex align-center justify-space-between">
                <div class="font-weight-bold">Upload Closing Packages</div>
              </v-card-text>
            </v-card>

            <v-card>
              <v-card-text>
                <v-stepper v-model="step" vertical>
                  <v-stepper-step
                    :complete="step > 1"
                    step="1"
                    color="secondary"
                  >
                    ESCROW INFORMATION
                    <small></small>
                  </v-stepper-step>
                  <v-stepper-content step="1">
                    <v-form
                      v-if="step === 1"
                      ref="validateEscrowInfoForm"
                      @submit.prevent="validateEscrowInfo"
                    >
                      <v-card class="pa-4" flat>
                        <v-row>
                          <v-col cols="12">
                            <v-text-field
                              v-model="
                                uploadDocsModel.uploadDocsVM.escrowCompanyName
                              "
                              label="Escrow Company Name"
                              :rules="[required]"
                              dense
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              v-model="
                                uploadDocsModel.uploadDocsVM.escrowofficerName
                              "
                              label="Escrow Officer Name"
                              :rules="[required]"
                              dense
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              v-model="
                                uploadDocsModel.uploadDocsVM.escrowOfficerEmail
                              "
                              label="Escrow Email"
                              :rules="[required, email]"
                              dense
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12">
                            <v-text-field
                              v-model="
                                uploadDocsModel.uploadDocsVM.escrowOfficerPhone
                              "
                              label="Escrow Phone"
                              :rules="[required, phone]"
                              v-mask="'(###) ###-####'"
                              dense
                              required
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card>
                      <v-btn color="secondary" type="submit"> Continue </v-btn>
                    </v-form>
                  </v-stepper-content>

                  <v-stepper-step
                    :complete="step > 2"
                    step="2"
                    color="secondary"
                  >
                    Settlement Agent Uploads
                    <small></small>
                  </v-stepper-step>

                  <v-stepper-content step="2">
                    <v-form
                      ref="validateUploadDocForm"
                      @submit.prevent="validateUploadDoc"
                    >
                      <v-card
                        class="pa-4 mb-4"
                        outlined
                        color="lightgrey"
                        width="100%"
                      >
                        <vue-dropzone
                          ref="submitPackageDropzone"
                          id="submitPackageDropzone"
                          :options="dropzoneOptions"
                          @vdropzone-error-multiple="vdropzoneErrorMultiple"
                          @vdropzone-success-multiple="vdropzoneSuccessMultiple"
                          @vdropzone-queue-complete="vdropzoneQueueComplete"
                        ></vue-dropzone>
                      </v-card>
                      <v-btn text @click="step--"> Back </v-btn>
                      <v-btn color="secondary" type="submit"> Continue </v-btn>
                    </v-form>
                  </v-stepper-content>

                  <v-stepper-step
                    :complete="step > 3"
                    step="3"
                    color="secondary"
                  >
                    REVIEW
                    <small>Please review your request</small>
                  </v-stepper-step>

                  <v-stepper-content step="3">
                    <v-form
                      v-if="step === 3"
                      lazy-validation
                      ref="submitClosingDocsForm"
                    >
                      <v-card class="pa-4" flat :disabled="stepLoading">
                        <v-row>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="uploadDocsModel.uploadDocsVM.loanNumber"
                              label="Loan Number"
                              :counter="9"
                              :rules="[
                                (v) => !!v || 'Loan Number is required',
                                (v) =>
                                  (v && v.length == 9) ||
                                  'Loan Number must consist of 9 digits',
                              ]"
                              v-mask="'NNXXXXXXX'"
                              dense
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="uploadDocsModel.uploadDocsVM.ssN4"
                              label="Last 4-digit SSN"
                              :counter="4"
                              :rules="[
                                (v) => !!v || 'This field is required',
                                (v) =>
                                  (v && v.length == 4) ||
                                  'This must consist of 4 digits',
                              ]"
                              v-mask="'XXXX'"
                              dense
                              required
                              :type="
                                uploadDocsModel.uploadDocsVM.ssN4Visiable
                                  ? 'text'
                                  : 'password'
                              "
                              :append-icon="
                                uploadDocsModel.uploadDocsVM.ssN4Visiable
                                  ? 'mdi-eye'
                                  : 'mdi-eye-off'
                              "
                              @click:append="
                                uploadDocsModel.uploadDocsVM.ssN4Visiable =
                                  !uploadDocsModel.uploadDocsVM.ssN4Visiable
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="4">
                            <v-text-field
                              v-model="uploadDocsModel.uploadDocsVM.zip5"
                              label="Property zip"
                              :counter="5"
                              :rules="[
                                (v) => !!v || 'This field is required',
                                (v) =>
                                  (v && v.length == 5) ||
                                  'This must consist of 5 digits',
                              ]"
                              v-mask="'XXXXX'"
                              dense
                              required
                              :type="
                                uploadDocsModel.uploadDocsVM.zip5Visiable
                                  ? 'text'
                                  : 'password'
                              "
                              :append-icon="
                                uploadDocsModel.uploadDocsVM.zip5Visiable
                                  ? 'mdi-eye'
                                  : 'mdi-eye-off'
                              "
                              @click:append="
                                uploadDocsModel.uploadDocsVM.zip5Visiable =
                                  !uploadDocsModel.uploadDocsVM.zip5Visiable
                              "
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="
                                uploadDocsModel.uploadDocsVM.escrowCompanyName
                              "
                              label="Escrow Company Name"
                              :rules="[required]"
                              dense
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="
                                uploadDocsModel.uploadDocsVM.escrowofficerName
                              "
                              label="Escrow Officer Name"
                              :rules="[required]"
                              dense
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="
                                uploadDocsModel.uploadDocsVM.escrowOfficerEmail
                              "
                              label="Escrow Email"
                              :rules="[required, email]"
                              dense
                              required
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" md="6">
                            <v-text-field
                              v-model="
                                uploadDocsModel.uploadDocsVM.escrowOfficerPhone
                              "
                              label="Escrow Phone"
                              :rules="[required, phone]"
                              v-mask="'(###) ###-####'"
                              dense
                              required
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            <v-chip
                              v-for="(closingFile, index) in uploadDocsModel
                                .uploadDocsVM.closingFileDisplayList"
                              :key="closingFile + '' + index"
                              class="ma-2"
                              color="error"
                              outlined
                            >
                              <v-icon left> mdi-file-document </v-icon>
                              {{ closingFile }}
                            </v-chip>
                          </v-col>
                          <v-col cols="12">
                            <v-btn text @click="step--"> Back </v-btn>
                            <v-btn
                              color="secondary"
                              type="button"
                              :loading="stepLoading"
                              @click="submitClosingDocs"
                            >
                              Submit
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-card>
                    </v-form>
                  </v-stepper-content>

                  <v-stepper-step
                    :complete="step > 4"
                    step="4"
                    color="secondary"
                  >
                    Finish
                  </v-stepper-step>

                  <v-stepper-content step="4">
                    <v-alert dense text type="success">
                      🎉 Uploaded your closing packages successfully.
                    </v-alert>
                  </v-stepper-content>
                </v-stepper>
              </v-card-text>
            </v-card>
          </section>
        </v-col>
      </v-row>
    </v-container>
    <PdfViewer :src="targetPdfPath" />
    <StatusDialog />
    <Footer />
  </section>
</template>

<script>
import TopBanner from "@/components/TopBanner";
import PdfViewer from "@/components/PdfViewer";
import Footer from "@/views/layouts/Footer";
import axios from "axios";
import vue2Dropzone from "vue2-dropzone";
import { rules, common } from "@/mixins";
import StatusDialog from "@/components/StatusDialog";

export default {
  components: {
    TopBanner,
    PdfViewer,
    Footer,
    StatusDialog,
    vueDropzone: vue2Dropzone,
  },
  async mounted() {
    this.resetModel();
    if (this.$route.query.loanNumber) {
      this.uploadDocsModel.uploadDocsVM.loanNumber =
        this.$route.query.loanNumber;
    }
  },
  data() {
    return {
      step: 0,
      stepLoading: false,
      dropzoneOptions: {
        acceptedFiles: ".pdf",
        url: `${process.env.VUE_APP_API_BASE_URL}/api/Feature/Public/Dropzone`,
        headers: {
          Authorization: `Basic ${btoa(
            this.$store.state.basicAuth.userName +
              ":" +
              this.$store.state.basicAuth.password
          )}`,
        },
        thumbnailWidth: 500,
        addRemoveLinks: true,
        maxFiles: 10,
        maxFilesize: 100, // 100 MB
        uploadMultiple: true,
        autoProcessQueue: false,
        paramName: () => "files",
        dictDefaultMessage:
          "<p class='text-h6'>Drop pdf files</p><p class='text-hsubtitle-1'>to Register</p><p class='text-hsubtitle-1'>(or <b>Click</b> to upload)</p>",
      },
      downloadDocsModel: {
        status: null,
        errorMessage: null,
        embeddedDocs: null,
      },
      uploadDocsModel: {
        status: null,
        errorMessage: null,
        uploadDocsVM: {
          loanNumber: null,
          ssN4: null,
          ssN4Visiable: false,
          zip5: null,
          zip5Visiable: false,
          escrowCompanyName: null,
          escrowofficerName: null,
          escrowOfficerEmail: null,
          escrowOfficerPhone: null,
          uploadDate: null,
          closingFileList: [],
          closingFileDisplayList: [],
        },
      },
      targetPdfPath: null,
    };
  },
  methods: {
    resetModel() {
      this.step = 0;
      this.downloadDocsModel = {
        status: null,
        errorMessage: null,
        embeddedDocs: null,
      };

      this.uploadDocsModel = {
        status: null,
        errorMessage: null,
        uploadDocsVM: {
          loanNumber: null,
          ssN4: null,
          ssN4Visiable: false,
          zip5: null,
          zip5Visiable: false,
          escrowCompanyName: null,
          escrowofficerName: null,
          escrowOfficerEmail: null,
          escrowOfficerPhone: null,
          uploadDate: null,
          closingFileList: [],
          closingFileDisplayList: [],
        },
      };

      this.stepLoading = false;
      if (this.$refs.submitPackageDropzone) {
        this.$refs.submitPackageDropzone.removeAllFiles();
      }
    },
    showPdfViewerByPath() {
      this.targetPdfPath = `${process.env.VUE_APP_API_BASE_URL}/web_resources/public/nwl_settlement_walkthrough.pdf`;
      this.$modal.show("pdf-viewer");
    },
    async showPDFViewer(embeddedDocModel) {
      this.$store.commit("setGlobalLoading", true);
      try {
        const basicAuth = `${this.$store.state.basicAuth.userName}:${this.$store.state.basicAuth.password}`;
        var resp = await axios.get(
          `${process.env.VUE_APP_API_BASE_URL}/api/BytePro/EmbeddedDoc/Public/One?fileDataId=${embeddedDocModel.fileDataId}&embeddedDocId=${embeddedDocModel.embeddedDocId}`,
          {
            headers: {
              Authorization: `Basic ${btoa(basicAuth)}`,
            },
          }
        );

        if (resp.status > 200) {
          throw resp.data;
        }

        this.openPdfNewTabWithBase64(
          resp.data.documentData,
          resp.data.description
        );
      } catch (e) {
        this.$store.commit("SHOW_STATUS_DIALOG", {
          status: "error",
          message: e,
        });
      }

      this.$store.commit("setGlobalLoading", false);
    },
    async verifyLoanInfo() {
      if (this.$refs.verifyLoanInfoForm.validate()) {
        this.stepLoading = true;

        const downloadDocsRet = await this.getClosingDocs(
          this.uploadDocsModel.uploadDocsVM
        );
        const uploadDocsRet = await this.checkClosingDocsUploadStatus(
          this.uploadDocsModel.uploadDocsVM
        );

        this.downloadDocsModel.status = downloadDocsRet.status;
        this.uploadDocsModel.status = uploadDocsRet.status;

        if (downloadDocsRet.status === 200) {
          this.downloadDocsModel.embeddedDocs = downloadDocsRet.data;
          this.downloadDocsModel.errorMessage = null;
          this.step = 1;
        } else {
          this.downloadDocsModel.errorMessage = downloadDocsRet.data;
        }

        if (uploadDocsRet.status === 200) {
          this.uploadDocsModel.uploadDocsVM = uploadDocsRet.data;
          this.uploadDocsModel.errorMessage = null;
          this.step = 1;
        } else {
          this.uploadDocsModel.errorMessage = uploadDocsRet.data;
        }

        this.stepLoading = false;
      }
    },
    async validateEscrowInfo() {
      if (this.$refs.validateEscrowInfoForm.validate()) {
        this.step = 2;
      }
    },
    async validateUploadDoc() {
      if (this.$refs.validateUploadDocForm.validate()) {
        if (this.$refs.submitPackageDropzone.getQueuedFiles().length > 0) {
          this.uploadDocsModel.uploadDocsVM.closingFileDisplayList = [];
          this.$refs.submitPackageDropzone.getQueuedFiles().forEach((file) => {
            this.uploadDocsModel.uploadDocsVM.closingFileDisplayList.push(
              file.name
            );
          });
          this.step = 3;
        } else {
          this.$store.commit("SHOW_STATUS_DIALOG", {
            status: "warning",
            message: "Please Upload a file",
          });
        }
      }
    },
    async checkClosingDocsUploadStatus(uploadDocsVm) {
      var resp = await axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/api/Feature/Public/CheckClosingDocsUploadStatus`,
          uploadDocsVm,
          {
            headers: {
              Authorization: `Basic ${btoa(
                this.$store.state.basicAuth.userName +
                  ":" +
                  this.$store.state.basicAuth.password
              )}`,
            },
          }
        )
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          return err.response;
        });

      return resp;
    },
    async getClosingDocs(uploadDocsVm) {
      var resp = await axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/api/Feature/Public/ClosingDocs`,
          uploadDocsVm,
          {
            headers: {
              Authorization: `Basic ${btoa(
                this.$store.state.basicAuth.userName +
                  ":" +
                  this.$store.state.basicAuth.password
              )}`,
            },
          }
        )
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          return err.response;
        });

      return resp;
    },
    async getEmbeddedDocDownloadLink(embeddedDocModel) {
      var resp = await axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/api/Feature/Public/EmbeddedDocDownloadLink`,
          embeddedDocModel,
          {
            headers: {
              Authorization: `Basic ${btoa(
                this.$store.state.basicAuth.userName +
                  ":" +
                  this.$store.state.basicAuth.password
              )}`,
            },
          }
        )
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          return err.response;
        });

      return resp;
    },
    async vdropzoneErrorMultiple(files, message) {
      this.$store.commit("SHOW_STATUS_DIALOG", {
        status: "error",
        message: `Sorry, ${message}`,
      });

      this.resetModel();
    },
    async vdropzoneSuccessMultiple(files, response) {
      if (this.uploadDocsModel.uploadDocsVM.closingFileList === null) {
        this.uploadDocsModel.uploadDocsVM.closingFileList = [];
      }

      response.forEach((sourcePath) => {
        this.uploadDocsModel.uploadDocsVM.closingFileList.push(sourcePath);
      });
    },
    async vdropzoneQueueComplete() {
      var resp = await axios
        .post(
          `${process.env.VUE_APP_API_BASE_URL}/api/Feature/Public/UploadClosingDocs`,
          this.uploadDocsModel.uploadDocsVM,
          {
            headers: {
              Authorization: `Basic ${btoa(
                this.$store.state.basicAuth.userName +
                  ":" +
                  this.$store.state.basicAuth.password
              )}`,
            },
          }
        )
        .then((resp) => {
          return resp;
        })
        .catch((err) => {
          return err.response;
        });

      if (resp.status === 200) {
        this.$store.commit("SHOW_STATUS_DIALOG", {
          status: "success",
          message: "Uploaded successfully.",
        });

        const downloadDocsRet = await this.getClosingDocs(
          this.uploadDocsModel.uploadDocsVM
        );
        const uploadDocsRet = await this.checkClosingDocsUploadStatus(
          this.uploadDocsModel.uploadDocsVM
        );

        this.downloadDocsModel.status = downloadDocsRet.status;
        this.uploadDocsModel.status = uploadDocsRet.status;

        if (downloadDocsRet.status === 200) {
          this.downloadDocsModel.embeddedDocs = downloadDocsRet.data;
        } else {
          this.downloadDocsModel.errorMessage = downloadDocsRet.data;
        }

        if (uploadDocsRet.status === 200) {
          this.uploadDocsModel.uploadDocsVM = uploadDocsRet.data;
        } else {
          this.uploadDocsModel.errorMessage = uploadDocsRet.data;
        }

        this.step = 4;
      } else {
        this.$store.commit("SHOW_STATUS_DIALOG", {
          status: "error",
          message: "Sorry, Upload failed.",
        });

        this.resetModel();
      }

      this.stepLoading = false;
    },
    async submitClosingDocs() {
      if (this.$refs.submitClosingDocsForm.validate()) {
        this.stepLoading = true;
        this.$refs.submitPackageDropzone.processQueue(); // Success or Error -> CompleteMultiple
      }
    },
  },
  mixins: [rules, common],
};
</script>
